import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Components
import { AddToCart } from "../product/add-to-cart";

const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  align-items: center;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #f8eee9;

  height: 75px;
  padding: 0 20px;

  & p {
    margin: 0;

    line-height: 28px;

    &.artwork-title-container {
      font-size: 16px;
      line-height: 22px;

      grid-column: 1 / 7;

      opacity: ${(props) => props.opacity};
      transition: 250ms opacity ease;

      & span {
        display: block;
      }

      & .artwork-title {
        margin: 0 35px 0 0;
      }

      & .artist-title {
        letter-spacing: 0.08em;
      }
    }
  }

  & .right-column {
    grid-column: 8 / 13;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & .artwork-enquiry-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & button {
      height: 50px;
      width: 120px;
      padding: 0 15px;

      line-height: 43px;
      text-align: center;

      transition: 250ms background-color ease, 250ms border ease;

      @media (max-width: 800px) {
        width: auto;
      }

      &.add-to-cart {
        border: 1px solid #a8b8c1;
        background-color: #a8b8c1;

        width: 120px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border: 1px solid #e9cfc0;
            background-color: #e9cfc0;
          }
        }
      }

      &.enquire {
        border: 1px solid #000000;
        margin: 0 0 0 20px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border: 1px solid #e9cfc0;
            background-color: #e9cfc0;
          }
        }
      }
    }
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    // align-items: flex-start;

    grid-template-columns: unset;
    grid-column-gap: unset;

    height: 70px;
    // padding: 0 20px;
    padding: 10px 25px 15px 25px;

    & .artwork-title-container {
      display: none;
    }

    & .right-column {
      grid-column: unset;
      width: 100%;

      & .artwork-enquiry-container {
        & button {
          height: 30px;
          min-width: 70px;

          line-height: 27px;

          padding: 0 9px;

          &.enquire {
            margin: 0 0 0 15px;
          }

          &.add-to-cart {
            width: 70px;
          }
        }
      }
    }
  }
`;

export const Banner = ({
  isBannerVisible,
  product,
  productVariant,
  available,
  priceOnApplication,
  setFormType,
  setIsProductEnquiryOpen,
  artistMetafield,
  getPriceOnApplication,
  price,
}) => {
  console.log("priceOnApplication:", priceOnApplication);

  return (
    <BannerContainer opacity={isBannerVisible ? 1 : 0}>
      <p className="artwork-title-container">
        <span className="artwork-title">{product.title}</span>
        {artistMetafield !== null && (
          <span className="artist-title uppercase">
            <Link to={`/shop/`} state={{ activeArtistFilter: artistMetafield }}>
              {artistMetafield}
            </Link>
          </span>
        )}
      </p>

      <div className="right-column">
        <p className="artwork-price-container">
          {available ? (
            <>
              {priceOnApplication === true || priceOnApplication === "true" ? (
                <>Price on Request</>
              ) : (
                <>{price === "£0" ? <>Price on Request</> : <>{price}</>}</>
              )}
            </>
          ) : (
            <>Sold</>
          )}
        </p>

        <div className="artwork-enquiry-container">
          <AddToCart
            variantId={productVariant.shopifyId}
            quantity={1}
            available={available}
            priceOnApplication={priceOnApplication}
            price={price}
          />

          <button
            className="enquire"
            onClick={() => {
              setFormType(`enquire`);
              setIsProductEnquiryOpen(true);

              document.getElementById(`___gatsby`).scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            Enquire
          </button>
        </div>
      </div>
    </BannerContainer>
  );
};
